import React from 'react'
//import './App.css';
import FetchUnsplash from './components/FetchUnsplash';
import Header from './components/Header';
import MemeGenerator from './components/MemeGenerator';


function App() {

  return (
    <div >
      {/* <Header />
      <MemeGenerator />} */}

      <FetchUnsplash/>

    </div>
  )
}

export default App
