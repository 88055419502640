import React, { Component } from "react"
import claims from "./DilagoClaims"
import dilagoStyle from '../dilagoStyle.css'


class FetchUnsplash extends Component {
    constructor() {
        super()
        this.state = {
            isLoaded: false,
            claim: '',
            randomImg: "",
            allMemeImgs: ''
        }

    }


    componentDidMount() {
        fetch('https://source.unsplash.com/1920x1080/?art,bw').then(data => {
            this.setState({ randomImg: data.url, isLoaded: true });
        })
            .catch(err => {
                console.log('Error happened during fetching!', err);
            });
    this.getRandomClaim()
    }


    getRandomClaim() {
        const randNum = Math.floor(Math.random() * claims.length)
        this.setState({ claim: claims[randNum] })
        console.log(claims[randNum]);
        console.log('claim: ' + this.state.claim);
    }

    randomPosition(){
        const randNumX = Math.floor(Math.random() * 500)
        const randNumY = Math.floor(Math.random() * 500)

    }


    render() {

        return (

            <div>
                <div>
                    {
                        !this.state.isLoaded ?
                            <h1>Dilago is Loading...</h1> :
                            <img src={this.state.randomImg} className='responsive'
                                alt="proplem?" />
                    }

                </div>
                {this.state.isLoaded && <h1 className='claim'>{this.state.claim}</h1>}

            </div>
        )
    }
}

export default FetchUnsplash