const claims = [
    "DILAGO - second 2 none",
    "DILAGO - voll wichtig",
    "DILAGO - wir (zer)ficken Ihr Design nach vorné",
    "DILAGO - wo ist die inspiration",
    "DILAGO - wir finden ideen wie sie keiner gut findet",
    "DILAGO - kreativ war gestern",
    "DILAGO - hier könnte Ihre Werbung stehen",
    "DILAGO - Unterhaltungsmanufaktur",
    "DILAGO - Entertainment war gestern",
    "DILAGO - Heute ist gute Laune",
    "DILAGO - Flexibel wie ein schweizer Uhrwerk",
    "DILAGO - Auf dem Heimweg ist die Hölle los",
    "DILAGO - garantiert biologisch abbaubar",
    "DILAGO - Nachhaltigkeit für jedermann",
    "DILAGO - jetzt wieder Amanda",
    "DILAGO - zurück zur Agenda Amanda",
    "DILAGO - Gags die man noch nicht gesehen hat",
    "DILAGO - ein Feuerwerk der Missgunst",
    "DILAGO - pure Gefühle",
    "DILAGO - erinnert sehr stark an Akte X … zu guten Zeiten",
    "DILAGO - mach ich aber nich …",
    "DILAGO - Der Ball liegt bei Ihnen",
    "DILAGO - schluss mit lustig",
    "DILAGO - Ich lass das jetzt so",
    "DILAGO - mediocre art in alarming frequency",
    "DILAGO - Hauptsache es knallt",
    "DILAGO - nach fest kommt ab",
    "DILAGO - das reicht jetzt",
    "DILAGO - eSports sind die Zukunft !",
    "DILAGO - Erlebnisse in s, sm, und xxx",
    "DILAGO - rufen sie uns nicht an",
    "DILAGO - wir fangen mit der party action an nach dem film gehts dann nach bedarf wild weiter in den content wahnsinn",
    "DILAGO - das ist der Jan Hammer",
    "DILAGO - Hostessen, Bier, Kaffee, Weiber, Brot & Spiele",
    "DILAGO - Jochen Schweizer agrar erlebnis paket",
    "DILAGO - überlegt euch mal nen claim",
    "DILAGO - Der Till geht kaputt",
    "DILAGO - Glückwunsch deine Eltern haben gebumst",
    "DILAGO - Leute attracten",
    "DILAGO - Über 1000 Drohnen musst du gehen",
    "DILAGO - Keine Projekte unter 30 Tausend",
    "DILAGO - die heißen auch alle so scheisse",
    "DILAGO - Leistung ist der Quotient aus Schmerz und Willkür",
    "DILAGO - Gleich gibt's Würstchen",
    "DILAGO - Get the proteine right into the muscle",
    "DILAGO - with Schnaps",
    "DILAGO - Wie kriegen wir die Kuh vom Eis",
    "DILAGO - Natürlich wollen wir damit Geld verdienen",
    "DILAGO - Wir generieren Learnings, auch Gute",
    "DILAGO - Selber schuld",
    "DILAGO - Benutze Schlauch mit Papagei",
    "DILAGO - Niemand hat die Absicht eine Mauer zu bauen",
    "DILAGO - Es geht um Geld",
    "DILAGO - 1000 Klicks, ein Euro",
    "DILAGO - Ich hole mir meine Information selber ihr Wichsbomben",
    "DILAGO - Dilago Cannes!",
    "DILAGO - effizientes abernten dot com",
    "DILAGO - agilität wirt bei uns Grossgeschrieben",
    "DILAGO - wir nehmen das rote Tape",
    "DILAGO - und nochn Aal auf die Hand!",
    "DILAGO - Ich muss verrückt sein!",
    "DILAGO - ich spiele gerne",
    "DILAGO - papa trinkt weil du weinst",
    "DILAGO - staubtrockene Unterhalgsudnelekotronik",
    "DILAGO - ...fast!",
    "DILAGO - Die Uhr tickt!",
    "DILAGO - das klingt so dramatisch",
    "DILAGO - produktiv, für unsere Verhältnisse",
    "DILAGO - den Ball muss man spielen",
    "DILAGO - keine Sportmetaphern mehr!",
    "DILAGO - macht eh keinen Sinn",
    "DILAGO - das wird schwierig!",
    "DILAGO - FAHRLÄSSIG!",
    "DILAGO - BUMMS. AUS.",
    "DILAGO - GEZ PASS AUF",
    "DILAGO - immer im Loop",
    "DILAGO - keep your security belt fasten",
    "DILAGO - schnall dich warm an",
    "DILAGO - keeps the wheels turning",
    "DILAGO - raus aus der Komfortzone",
    "DILAGO - hinein ins We can’t feeling",
    "DILAGO - die Partei",
    "DILAGO - Hotel Dilago",
    "DILAGO - i Love this Company",
    "DILAGO - 1-800-411-PAIN",
    "DILAGO - Shake Hands und gute Kontakte",
    "DILAGO - Am Ball der Zeit",
    "DILAGO - willkommen im Büro",
    "DILAGO - keine Zeit für Business",
    "DILAGO - an AGCO company",
    "DILAGO - ne kleine Pizza hätte es auch getan",
    "DILAGO - Schöner Content, nette Bilder",
    "DILAGO - Grösser als Gross",
    "DILAGO - von der Komfortzone in die Gewinnzone",
    "DILAGO - Was kann man denn da mit Geld noch machen …?",
    "DILAGO - irgendwas mit Medien…",
    "DILAGO - Kunden haften für Ihre Inhalte",
    "DILAGO - Alles andere wäre fahrlässig",
    "DILAGO - keep climbing",
    "DILAGO - Kommen Sie zu uns bevor wir zu Ihnen kommen",
    "DILAGO - Ihr Partner wenn Sie Profis suchen",
    "DILAGO - Good is not good enough",
    "DILAGO - more than pixels",
    "DILAGO - hot ops to be discussed",
    "DILAGO - FUCK YEAH",
    "DILAGO - ich hab mein handy immer dabei",
    "DILAGO - Knipser und Handlanger",
    "DILAGO - Home of the Atlanta beaver fuckers",
    "DILAGO - So kooperativ wie nötig",
    "DILAGO - Die schaffen echt Content ran!",
    "DILAGO - Inhalt ermüdet nur",
    "DILAGO - ab hier kann es nur billiger werden",
    "DILAGO - warte, ich mach dat",
    "DILAGO - kind retards",
    "DILAGO - true snowball talk",
    "DILAGO - Quo vadis, motherfucker!",
    "DILAGO - mindset wie es sinkt und lacht",
    "DILAGO - wir gehen mit euch ins Risiko",
    "DILAGO - Mike, Thoughts? ....",
    "DILAGO - Messe ist Krieg",
    "DILAGO - 192.168.tequila",
    "DILAGO - Leben am Limit",
    "DILAGO - Let's talk Tacheles (N.Z.)",
    "DILAGO - Hinein ins we can't feeling",
    "DILAGO - Sekundenschlaf ist viel zu kurz",
    "DILAGO - Eine Gin-Gin Situation",
    "DILAGO - Ne gesunde Portion Nowzismus!",
    "DILAGO - Ideal StandArt",
    "DILAGO - Der Zenit der Niedertracht",
    "DILAGO - Auch ein blinder Schuster hat helle Momente",
    "DILAGO - Wir machen sehr viel richtig",
    "DILAGO - Zuhause gibts ja nix zu essen",
    "DILAGO - Zwischenlösung bis Q3",
    "DILAGO - wer Marge will muss freundlich sein",
    "DILAGO - Gesundheit, kein corona",
    "DILAGO - Kein Anspruch unter dieser Nummer",
    "DILAGO - Mit feindlichen Grüßen",
    "DILAGO - Ehrlich und menschlich",
    "DILAGO - Motor laufen lassen im Kopf",
    "DILAGO - A critical mind",
    "DILAGO - Ich hatte keine Bock drauf...",
    "DILAGO - Die können auf Ihre leads scheissen",
    "DILAGO - Mehr Pixel, mehr Kohle",
    "DILAGO - JEKÄMPFT HAMSE !",
    "DILAGO - bei 0% is Handy aus…",
    "DILAGO - Manni, der Libero",
    "DILAGO - Gestern, auf Madeira…",
    "DILAGO - Mit Korn fängste an, mit Korn hörste auf …",
    "DILAGO - Die Band für ihre Party",
    "DILAGO - separating the men from the boyz",
    "DILAGO - from nothing to money",
    "DILAGO - Bitte die Tüte offen lassen, die Opportunities kommen gerade aus dem Ofen",
    "DILAGO - Ich hab ne Agentur zu Besuch…",
    "DILAGO - what a feeling!",
    "DILAGO - mit 79 in Rente",
    "DILAGO - das hättet ihr sein können",
    "DILAGO - ich hab dich doch angerufen",
    "DILAGO - die spann ich vor den Karren",
    "DILAGO - Laufwege optimieren",
    "DILAGO - Wenn es blutet können wir es versteuern",
    "DILAGO - da hilft nur ein Bombenteppich…",
    "DILAGO - Eis ist der Tod auf Rädern",
    "DILAGO - Der dicke Verkäufer mit den flotten Schuhen",
    "DILAGO - Trink mer noch a Mittelmaß",
    "DILAGO - Übergangsjacke der Emotion",
    "DILAGO - mit uns ziehen Sie die Ereigniskarte",
    "DILAGO - Im Herzen immer noch AGCO",
    "DILAGO - Wie Schach nur ohne Würfel",
    "DILAGO - Wenn Weihnachen ist, hol wir die Kugeln raus",
    "DILAGO - #unserefilterblaseistidiegeilstediggi!",
    "DILAGO - Hold my WD40!",
    "DILAGO - Die Speerspitze des Agilè-Asitalks",
    "DILAGO - Rosencall mit Schnitzel",
    "DILAGO - Wo sind meine Margenficker?",
    "DILAGO - Ich hab diesen Internet Turbo runtergeladen!",
    "DILAGO - Achtung! Wertvolle Turnierpferde.",
    "DILAGO - Wenn alle Kuchen essen, muss man sich Donuts holen!",
    "DILAGO - Lean but able",
    "DILAGO - Die ham auch hartes Brot.",
    "DILAGO - Auf allen Kanälen in voller Blüte das absolute Maximum ausstrahlen",
    "DILAGO - der Letzte der steht kriegt den Pudding",
    "DILAGO - mit dem kleinen Zeh aus dem Fenster raus",
    "DILAGO - Projekte für Profis",
    "DILAGO - allein in der lobby",
    "DILAGO - sudden death",
    "DILAGO - nur ein wurm",
    "DILAGO - Fertig!",
    "DILAGO - N bisschen asi und viel Klasse",
    "DILAGO - Das Ende der Wertschöpfungskette der Muppet Show",
    "DILAGO - Im Mittelstand angekommen",
    "DILAGO - SDSSA (Schliess deine Scheisse selber an)",
    "DILAGO - Lasst uns die Expectations managen",
    "DILAGO - Amandatory",
    "DILAGO - Du hast keine Fans wenn du billig bist",
    "DILAGO - Der Goldregen der Dämlichkeit",
    "DILAGO - bezahltes wormsen",
    "DILAGO - a gamechanging medipack",
    "DILAGO 3 - der dritte Teil der beliebten DILAGO Reihe",
    "DILAGO - see how better we are!",
    "DILAGO - wir sitzen immer auf gepackten Kisten",
    "DILAGO - Ich habe keine Angst mehr",
    "DILAGO - dünne Füße heißes Eis",
    "DILAGO - ein ganz schmaler Spagat",
    "DILAGO - Leichte Unterhaltung, starke Getränke",
    "DILAGO - das machen wir nicht noch mal!",
    "DILAGO - gegen Windmühlen rennen",
    "DILAGO - Video aus, WORMS an!",
    "DILAGO - “Con Voyage” (FoodTrucks)",
    "DILAGO - kein Kopf, kein Magen, keine Kirmes",
    "DILAGO - solange man nix mit denen zu tun hat sind die auch cool",
    "DILAGO - für Procrastination ist uns keine Anstrengung zu groß",
    "DILAGO - LÖSUNGSSCHUPPEN",
    "DILAGO - mich widern Agenturen nur noch an",
    "DILAGO - very PRG",
    "DILAGO - sehr illegal aus dem Fenster legen",
    "DILAGO - wir haben ein paar hundert Bilder. Wir haben ein paar hundert Sprüche.->",
    "DILAGO - wenn wir Glück haben kommt was Gutes bei raus",
    "DILAGO - das Tanzorchester Ehrenfeld der Bildgestaltung",
    "DILAGO - Facettenreich wie eine Kugel",
    "DILAGO - kein Hals aber viel Charakter",
    "DILAGO - Education ist wahrscheinlich das Thema",
    "DILAGO - wir haben 100 Leute gefragt:”Was ist letzte Preis?”",
    "DILAGO - Ich hab mit den Kindern gespielt und die Spracherkennung war noch an",
    "DILAGO - Spielspaß mit vielen Schiebern",
    "DILAGO - Ich fand die schon Scheiße, als die noch PRG LAB hießen!",
    "DILAGO - Guerilla Marketing zu Ende gespielt",
    "DILAGO - 🍿",
    "DILAGO - Volle Flexibilität voraus!",
    "DILAGO - Bis hierhin und nicht weiter (meta-humans)",
    "DILAGO - Leap Controller my ass",
    "DILAGO - Warum sollte das denn nicht gehen?",
    "DILAGO - kurz im Weinkeller “F5” drücken",
    "DILAGO - Pick and Ban",
    "DILAGO - Endlich am Vorstand vorbei investieren",
    "DILAGO - Nepper, slapper, Bauernfänger",
    "DILAGO - please insert another coin for more content",
    "DILAGO - passives Agentur Einkommen",
    "DILAGO - Heimathafen des Videokapitäns",
    "DILAGO - Der Videokapitän auf großer Fahrt",
    "DILAGO - Toller Erfolg!",
    "DILAGO - Die könnten alles….aber die machen DAS!",
    "DILAGO - 7000 netto",
    "DILAGO - ist wie 24 nur mit ich werde jetzt Präsident",
    "DILAGO - wir bezahlen dich in Reichweite"]

export default claims